<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Guaritas e Portarias</h2>

            <p>
              Em ambientes que exigem maior segurança, como a portaria e
              guaritas de um condomínio, onde há grande fluxo de pessoas e
              veículos, é muito importante colocar insulfilmes que
              <strong>impeçam a visualização de fora para o interior</strong>
              das portarias e guaritas dos condomínios. Dando maior conforto e
              segurança aos funcionários que estarão no local.
            </p>

             <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <p>
              Além das propriedades de redução de calor e radiação UV, a
              aplicação de insulfilme para guarita e portaria de condomínio traz
              <strong>privacidade</strong> não só para proteger dos olhos
              curiosos das pessoas, mas é muito útil no período noturno contra
              os faróis de automóveis, tornando o interior e o exterior de suas
              janelas quase opacos, de modo que nada pode ser visto em ambos os
              lados.
            </p>

            <p>
              Níveis elevados de luz ainda podem passar, por isso é uma boa
              escolha se você quiser deixar um pouco de sol ou luz artificial
              entrar no ambiente.
            </p>

            <p>
              Os insulfilmes para guaritas e portarias mais usuais são os
              insulfilmes fumes e fumes invertidos, que são refletivos prata
              pelo lado externo, e fume para o lado interno
            </p>

            <h3>Visibilidade do insulfilme para guaritas e portarias</h3>

            <Table class="mb-0-5">
              <tr>
                <td>Transmissão de Luz Visível</td>
                <td class="text-right" width="20%">5%</td>
              </tr>
              <tr>
                <td>Reflexão de Energia Solar</td>
                <td class="text-right" width="20%">5%</td>
              </tr>
              <tr>
                <td>Transmissão de Raios Ultravioletas</td>
                <td class="text-right" width="20%">>5%</td>
              </tr>
              <tr>
                <td>Energia Total Refletida</td>
                <td class="text-right" width="20%">41%</td>
              </tr>
            </Table>

            <p>Cor fume</p>

            <h3>Aplicação de insulfilmes para guaritas e portarias</h3>

            <p>
              É possível aplicar insulfilmes para guaritas e portarias com
              materiais de qualidade e com garantia de fábrica, impedindo a
              visualização de fora para o interior do ambiente, gerando
              privacidade e reduzindo o calor e radiação UV do ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar
              na seguranca e economia com ar condicionado.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");
const Table = () =>
  import(/* webpackChunkName: "Table" */ "@/components/shared/table/table.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
    Table,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        
        {
          description:
            "Insulfilm G5 aplicado na região de pinheiros para dar privacidade, reduzir a luz e calor",
          idLocation: 3,
          src: "/images/fotos/condominio-arruda/insulfilm-g5-guarita-condominio-arruda-134903329",
          thumb: "/images/fotos/condominio-arruda/small/insulfilm-g5-guarita-condominio-arruda-134903329",
          title: "Película para Guarita",
        },
        
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Guaritas e Portarias");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm nas guaritas e portarias para privacidade, segurança e redução de calor"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>